import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IApiSingleResponseBase} from '../../types';
import {ICompanyOutput} from '../../model/common';

export function getCompanyDataFromNipAPI(authToken: string, taxId: string): Observable<IApiSingleResponseBase<ICompanyOutput>> {
    return palipaliAPI.get(`api/common/company_info/${taxId}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
