import {Box, Button, Stack, ThemeProvider} from '@mui/material';
import {FormikHelpers, useFormik} from 'formik';
import React, {useEffect} from 'react';
import * as Yup from 'yup';
import {IGeoPointInput} from '../../model/common';
import AddressAutocomplete from '../../theme/components/AdressAutocomplete';
import CheckboxFormControl from '../../theme/components/CheckboxFormControl';
import DatePickerControl from '../../theme/components/DatePickerControl';
import DateRangePickerControl from '../../theme/components/DateRangePickerControl';
import DateTimePickerControl from '../../theme/components/DateTimePickerControl';
import FileFormControl from '../../theme/components/FileFormControl';
import PackageSizeFormControl from '../../theme/components/PackageSizeFormControl';
import PasswordFormControl from '../../theme/components/PasswordFormControl';
import RadioFormControl from '../../theme/components/RadioFormControl';
import SelectFormControl from '../../theme/components/SelectFormControl';
import SwitchFormControl from '../../theme/components/SwitchFormControl';
import TextFormControl from '../../theme/components/TextFormControl';
import {IFileUploadListElement} from '../../types';
import {DateRange} from '../DateRangePickerHost';
import Translation from '../Translation';
import TextMaskFormControl, {TextMaskType} from '../../theme/components/TextMaskFormControl';
import {isSameCollection, isSameValue} from '../../utils/comparatorUtils';
import {IGoogleAddressParts} from '../../model/location';

export interface FormikGroupFieldConfig {
    formikControlType: FormikFormControlType;
    fields: FormikFieldConfig[];
    controlsPerRow?: number;
}

export interface FormikFieldConfig {
    name: string;
    label: string;
    validation: any;
    type: FormikFieldTypes;
    endAdornment?: React.ReactNode;
    disabled?: boolean;
    formik?: any;
    options?: any;
    className?: string;
    sx?: any;
    variant?: 'text' | 'contained' | 'outlined';
    size?: 'small' | 'large' | 'medium';
    autofocus?: boolean;
    placeholder?: string;
    multipleFiles?: boolean;
    customDisabled?: (values: any) => boolean;
    customOptions?: (values: any) => any[];
    acceptedFileTypes?: string;
    disablePast?: boolean;
    onFileChange?: (file: IFileUploadListElement, name: string) => void;
    customHandleChange?: (value: any, setFieldValue: (field: string, value: any) => void, values?: any) => void;
    customEventChange?: (controlName: string, value: any) => void;
    rows?: number;
    getFileIdValue?: boolean;
    maxRows?: number;
    characterLimit?: number;
    controlFlexWidth?: number;
    noOptionText?: string | null;
    onChange?: (addressLine: string, point: IGeoPointInput | null) => void;
    isAddressBookShown?: boolean;
    onAddressBookAction?: () => void;
    isRequired?: boolean;
    initialDates?: DateRange;
    sublabel?: string;
    maxDate?: Date | string | number;
    minDate?: Date | string | number;
    minDateTime?: Date | string | number;
    startFromTomorrow?: boolean;
    dateFormat?: string;
    isCustomLabelTranslation?: boolean;
    translationConfigValues?: {[key: string]: any};
    translationComponents?: React.ReactElement[] | {readonly [tagName: string]: React.ReactElement};
    customContent?: React.ReactNode;
    switchLabelPlacement?: 'start' | 'end' | 'top' | 'bottom';
    textMaskType?: TextMaskType;
    formControlClassName?: string;
    flexSettings?: string;
    maxNumberLength?: number;
    lettersOnly?: boolean;
    valueToDisable?: string;
}

export enum FormikFieldTypes {
    TEXT = 'text',
    TEXTAREA = 'textarea',
    PASSWORD = 'password',
    NUMBER = 'number',
    EMAIL = 'email',
    SELECT = 'select',
    DATE = 'date',
    DATERANGE = 'dateRange',
    DATETIME = 'dateTime',
    FILE = 'file',
    MASKED = 'masked',
    PACKAGE_SIZE = 'packageSize',
    RADIO = 'radio',
    CUSTOM_CHECKBOX = 'customCheckbox',
    SUBMIT_BUTTON = 'submitButton',
    ADDRESS_AUTOCOMPLETE = 'addressAutocomplete',
    SWITCH = 'switch',
    CHECKBOX = 'checkbox',
    CUSTOM = 'custom',
}

export const FormikRegexSettings = {
    EMAIL: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    NIP: /^[0-9]{10}$/,
    POSTAL_CODE: /^[0-9]{2}-[0-9]{3}$/,
    PHONE: /^[0-9]{9}$/,
    PASSWORD: /^(?=.*[a-z])(?=.*[0-9]|.*[*.!@$%^&(){}\[\]:;<>,?/~_+-=|]).{8,}$/,
    LETTERS_AND_DASH: /^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s'-]+$/,
    NO_REPEATED_DASH_IN_THE_MIDDLE: /^(?!.*([-\s])\1)(?!.*-\s)(?!.*\s-).*$/,
    NO_DASH_END: /^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]([A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s'-]*[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ])?$/,
    MUST_CONTAIN_LETTER_WITHOUT_WHITESPACES: /^(?!.*\s$)(?!^\s).*[a-zA-Z].*$/,
} as const;

export enum FormikFormControlType {
    CONTROL = 'control',
    GROUP_CONTROL = 'group_control',
}

export interface FormikFormConfig {
    formId: string;
    fields: FormikFieldConfig[] | FormikGroupFieldConfig[];
    initialValues: any;
    onSubmit: (values: any, formikHelpers?: FormikHelpers<any>) => void;
    updatedValues?: any;
    validateOnMount?: boolean;
    theme?: any;
    submitAllowed?: (submitAllowed: boolean) => void;
    additionalValidationRules?: any;
    isSinglePackageSelect?: boolean;
    onPackageDimensionChange?: (value: 's' | 'm' | 'l') => void;
    packageControlLabel?: string;
    customEventChange?: (controlName: string, value: any) => void;
    rows?: number;
    maxRows?: number;
    enableReinitialize?: boolean;
    characterLimit?: number;
    formClassName?: string;
    onAddressChange?: (addressLine: string, point: IGeoPointInput | null, addressParts: IGoogleAddressParts) => void;
    onAddressClear?: () => void;
    onAddressBookAction?: () => void;
    shouldValidate?: boolean;
    shouldApplyCustomValidation?: boolean;
    isAutocompleteError?: boolean;
    isDirty?: boolean;
    onFormikValuesChange?: (formik: any) => void;
}

const FormikForm: React.FC<FormikFormConfig> = ({
    formId,
    fields,
    initialValues,
    onSubmit,
    validateOnMount,
    updatedValues,
    theme,
    submitAllowed,
    additionalValidationRules,
    isSinglePackageSelect,
    onPackageDimensionChange,
    packageControlLabel,
    customEventChange,
    formClassName,
    onAddressChange,
    onAddressBookAction,
    onAddressClear,
    enableReinitialize,
    shouldValidate,
    shouldApplyCustomValidation,
    isAutocompleteError,
    isDirty,
    onFormikValuesChange,
}) => {
    const validationSchema = () => {
        let schemaFields: Record<string, any> = {};

        fields.forEach((field) => {
            if (field.hasOwnProperty('formikControlType') && field.formikControlType === FormikFormControlType.GROUP_CONTROL) {
                const groupFields = (field as FormikGroupFieldConfig).fields;

                groupFields.forEach((subField) => {
                    if (subField.type !== FormikFieldTypes.SUBMIT_BUTTON) {
                        schemaFields[subField.name] = subField.validation;
                    }
                });
            } else {
                if (field.type !== FormikFieldTypes.SUBMIT_BUTTON) {
                    schemaFields[field.name] = field.validation;
                }
            }
        });

        return Yup.object().shape({
            ...schemaFields,
            ...(additionalValidationRules || {}),
        });
    };

    const formik = useFormik({
        validateOnMount: validateOnMount || false,
        initialValues,
        enableReinitialize: enableReinitialize || false,
        validationSchema,
        onSubmit,
    });

    useEffect(() => {
        if (shouldValidate !== undefined && shouldValidate === true) {
            formik.validateForm().then((errors) => {
                Object.keys(errors).forEach((fieldName) => {
                    formik.setFieldTouched(fieldName, true, false);
                });
            });
        }
    }, [shouldValidate]);

    useEffect(() => {
        if (formik.values && onFormikValuesChange) {
            onFormikValuesChange(formik.values);
        }
    }, [formik.values]);

    useEffect(() => {
        if (updatedValues) {
            formik.setValues(updatedValues);
        }
    }, [updatedValues]);

    useEffect(() => {
        if (!submitAllowed) return;
        if (formik.isValid && isDirty === true) {
            return submitAllowed ? submitAllowed(true) : null;
        }
        if (shouldApplyCustomValidation) {
            const validationPromise = validationSchema().validate(formik.values);

            validationPromise.then(
                (validData) => {
                    if (validData) {
                        submitAllowed(true);
                    }
                },
                () => {
                    submitAllowed(false);
                }
            );
        } else {
            if (formik.isValid && formik.dirty && !isSameValue(initialValues, formik.values)) {
                submitAllowed ? submitAllowed(true) : null;
            } else {
                submitAllowed ? submitAllowed(false) : null;
            }
        }
    }, [formik.isValid, formik.dirty, formik.values]);

    const checkboxHandleChange = (event: React.ChangeEvent<HTMLInputElement>, formControlName: string) => {
        const {name, checked} = event.target;

        if (onPackageDimensionChange && !isSinglePackageSelect) {
            onPackageDimensionChange(formControlName as 's' | 'm' | 'l');
        }

        if (isSinglePackageSelect) {
            Object.keys(formik.values).forEach((fieldName) => {
                if (fieldName !== name) {
                    formik.setFieldValue(fieldName, false);
                }
                if (checked && fieldName === name) {
                    onPackageDimensionChange(name as 's' | 'm' | 'l');
                }
            });
        }

        formik.setFieldValue(name, checked);
        if (!formik.touched[formControlName]) {
            formik.setFieldTouched(formControlName, true);
        }
    };

    const getFieldComponent = (field: FormikFieldConfig) => {
        switch (field.type) {
            case FormikFieldTypes.TEXT:
                return (
                    <TextFormControl key={field.name} {...field} customEventChange={customEventChange} type={field.type} formik={formik} />
                );
            case FormikFieldTypes.NUMBER:
                return (
                    <TextFormControl
                        key={field.name}
                        {...field}
                        customEventChange={customEventChange}
                        type={FormikFieldTypes.NUMBER}
                        formik={formik}
                    />
                );
            case FormikFieldTypes.MASKED:
                return (
                    <TextMaskFormControl
                        key={field.name}
                        {...field}
                        customEventChange={customEventChange}
                        formik={formik}
                        textMaskType={field.textMaskType}
                    />
                );
            case FormikFieldTypes.TEXTAREA:
                return <TextFormControl type="textarea" key={field.name} {...field} formik={formik} />;
            case FormikFieldTypes.PASSWORD:
                return <PasswordFormControl key={field.name} {...field} formik={formik} />;
            case FormikFieldTypes.SELECT:
                return <SelectFormControl key={field.name} {...field} customEventChange={customEventChange} formik={formik} />;
            case FormikFieldTypes.RADIO:
                return <RadioFormControl key={field.name} {...field} customEventChange={customEventChange} formik={formik} />;
            case FormikFieldTypes.SUBMIT_BUTTON:
                return (
                    <Button
                        key={field.name}
                        fullWidth
                        disabled={!formik.isValid || !formik.dirty}
                        size={field.size || 'large'}
                        form={formId}
                        sx={field.sx}
                        type="submit"
                        variant={field.variant || 'contained'}
                        className={field.name}>
                        <Translation text={field.label} />
                    </Button>
                );
            case FormikFieldTypes.PACKAGE_SIZE:
                return (
                    <PackageSizeFormControl
                        key={field.name}
                        formik={formik}
                        formControlName={field.name}
                        values={formik.values}
                        valueToDisable={field.valueToDisable}
                        customDisabled={field.customDisabled}
                        label={packageControlLabel}
                        checkboxHandleChange={(event) => checkboxHandleChange(event, field.name)}
                    />
                );
            case FormikFieldTypes.FILE:
                return <FileFormControl key={field.name} {...field} customEventChange={customEventChange} formik={formik} />;
            case FormikFieldTypes.DATE:
                return (
                    <DatePickerControl
                        key={field.name}
                        {...field}
                        maxDate={field.maxDate}
                        minDate={field.minDate}
                        startFromTomorrow={field.startFromTomorrow}
                        customEventChange={customEventChange}
                        disablePast={field.disablePast}
                        dateFormat={field.dateFormat}
                        formik={formik}
                    />
                );
            case FormikFieldTypes.DATETIME:
                return (
                    <DateTimePickerControl
                        key={field.name}
                        {...field}
                        maxDate={field.maxDate}
                        minDate={field.minDate}
                        minDateTime={field.minDateTime}
                        startFromTomorrow={field.startFromTomorrow}
                        customEventChange={customEventChange}
                        disablePast={field.disablePast}
                        isRequired={field.isRequired}
                        formik={formik}
                    />
                );
            case FormikFieldTypes.DATERANGE:
                return (
                    <DateRangePickerControl
                        key={field.name}
                        {...field}
                        disablePast={field.disablePast}
                        customEventChange={customEventChange}
                        initialDates={field.initialDates}
                        formik={formik}
                    />
                );
            case FormikFieldTypes.ADDRESS_AUTOCOMPLETE:
                return (
                    <AddressAutocomplete
                        label={field.label}
                        defaultValue={formik.values[field.name]}
                        noOptionText={field.noOptionText}
                        onChange={(addressLine: string, point: IGeoPointInput | null, addressParts: IGoogleAddressParts) => {
                            onAddressChange(addressLine, point, addressParts);
                            formik.setFieldValue(field.name, addressLine);
                            formik.setFieldTouched(field.name, true);
                        }}
                        isAddressBookShown={field.isAddressBookShown}
                        onAddressBookAction={onAddressBookAction}
                        onAddressClear={
                            onAddressClear
                                ? () => {
                                      onAddressClear();
                                      formik.setFieldTouched(field.name, true);
                                  }
                                : undefined
                        }
                        isRequired={field.isRequired}
                        isError={isAutocompleteError || (formik?.touched?.[field.name] && !!formik?.errors?.[field.name])}
                    />
                );
            case FormikFieldTypes.SWITCH:
                return (
                    <SwitchFormControl
                        label={field.label}
                        customEventChange={customEventChange}
                        sublabel={field.sublabel}
                        key={field.name}
                        switchLabelPlacement={field.switchLabelPlacement}
                        {...field}
                        formik={formik}
                    />
                );
            case FormikFieldTypes.CHECKBOX:
                return (
                    <CheckboxFormControl
                        name={field.name}
                        label={field.label}
                        customEventChange={customEventChange}
                        {...field}
                        formik={formik}
                    />
                );
            case FormikFieldTypes.CUSTOM:
                return field.customContent;
            default:
                return null;
        }
    };

    const renderFormControls = () => {
        const rows: JSX.Element[][] = [];

        fields.map((field) => {
            if (field.hasOwnProperty('formikControlType') && field.formikControlType === FormikFormControlType.GROUP_CONTROL) {
                const groupFields = (field as FormikGroupFieldConfig).fields;

                const groupRow: JSX.Element[] = [];

                groupFields.map((groupField) => {
                    const fieldComponent = getFieldComponent(groupField);
                    if (fieldComponent) {
                        const controlsPerRow = field.controlsPerRow || 1;
                        const controlFlexWidth = groupField.controlFlexWidth || 1;
                        const flexSetup = groupField.flexSettings || `1 0 ${100 / controlFlexWidth}%`;

                        groupRow.push(
                            <Box key={groupField.name} style={{flex: flexSetup}}>
                                {fieldComponent}
                            </Box>
                        );

                        if (groupRow.length === controlsPerRow) {
                            rows.push([...groupRow]);
                            groupRow.length = 0;
                        }
                    }
                });

                if (groupRow.length > 0) {
                    rows.push([...groupRow]);
                }
            } else {
                const fieldComponent = getFieldComponent(field);
                if (fieldComponent) {
                    const controlFlexWidth = field.controlFlexWidth || 1;
                    const flexSetup = field.flexSettings || `1 0 ${100 / controlFlexWidth}%`;
                    const singleFieldRow = (
                        <Box
                            key={field.name}
                            style={{display: 'flex', flex: flexSetup}}
                            className={`formik-form-single-field-row-wrapper form-control-wrapper-${field.name} form-control-wrapper-custom-${field.className}`}>
                            {fieldComponent}
                        </Box>
                    );

                    rows.push([singleFieldRow]);
                }
            }
        });

        return rows.map((row, index) => (
            <Stack
                key={`${formId}-stack-field ${formId}-stack-field-row-${index}`}
                direction={'row'}
                className={`form-row-content`}
                spacing={2}>
                {row}
            </Stack>
        ));
    };
    const preventDefaultEventsSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit();
    };
    return (
        <ThemeProvider theme={theme}>
            <form onSubmit={preventDefaultEventsSubmit} className={`custom-form ${formClassName ? formClassName : ''}`} id={formId}>
                <Stack className={`form-controls-wrapper`} direction={'column'}>
                    {renderFormControls()}
                </Stack>
            </form>
        </ThemeProvider>
    );
};

export default FormikForm;
